<template>
  <v-card>
    <v-card-title v-text="chartTitle"/>
    <v-card-text>
      <v-chip-group v-model="markedLabels" column multiple active-class="grey lighten-3">
        <v-chip
            v-for="(label, key) in Object.keys(timeReport)"
            :key="label._id"
            :color="backgroundColor[key]"
            text-color="black"
            :style="{'text-decoration': markedLabels.includes(label) ? 'line-through' : null}"
            :value="label"
        >
          <v-icon left small>
            {{ getIconName(label) }}
          </v-icon>
          {{ label }}
        </v-chip>
      </v-chip-group>
      <PieChart v-if="loaded" :chart-data="charData" :options="options"/>
    </v-card-text>
  </v-card>

</template>
<script>
import PieChart from "~/views/layouts/components/PieChart";
import {interpolateRainbow, interpolateSpectral} from "d3-scale-chromatic";

export default {
  components: {PieChart},
  data: () => ({
    loaded: false,
    markedLabels: [],
    changeableReports: null
  }),
  props: {
    timeReport: {
      type: Object,
      default: null
    },
    colorScheme: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.loaded = true;
  },
  computed: {
    chartTitle() {
      return `${this.$t('label.time_spent_on')} ${this.$t(this.title)}`;
    },
    reports: {
      get() {
        let self = this,
            timeReport = this.timeReport;
        return Object.entries(timeReport).length
            && Object.fromEntries(Object.entries(timeReport).filter(([index]) => !self.markedLabels.length || !self.markedLabels.includes(index)));

      }
    },
    labels() {
      return Object.values(this.reports).map(item => item.label);
    },
    time() {
      return Object.values(this.reports).map(item => item.time);
    },
    timeData() {
      return Object.values(this.reports).map(item => item.total_seconds);
    },
    backgroundColor() {
      return Object.keys(this.timeReport).map((key, index) =>
          this.markedLabels.length && this.markedLabels.includes(key) ? null :
            this.generateColor(index, Object.keys(this.timeReport).length)
      );
    },
    borderColor() {
      return this.backgroundColor.map((color) => color.replace(')', '0.5)'));
    },
    charData() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: '# hours',
            data: this.timeData,
            backgroundColor: this.backgroundColor.filter(Boolean),
            borderColor: this.backgroundColor.filter(Boolean),
            borderWidth: 1,
            hoverBorderWidth: 0,
            hoverBorderColor: 'rgba(0,0,0,0)'
          }
        ]
      };
    },
    options() {
      return {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          callbacks: {
            label: (tooltipItem) => this.labels[tooltipItem.index] + ': '
                + this.time[tooltipItem.index].replace(/(\d+):(\d+):(\d+)/g, '$1h $2m $3')
          }
        }
      }
    },
  },
  methods: {
    /**
     *
     * @param position
     * @param total
     * @returns {string}
     */
    generateColor(position, total) {
      let max = 1,
          pace = max / total,
          colorTon = (pace * (position + 1));
      switch (this.colorScheme) {
        case 'rainbow':
          return interpolateRainbow(max - colorTon);
        case 'spectral':
        default:
          return interpolateSpectral(max - colorTon);
      }
    },

    /**
     *
     * @param hexColor
     * @param opacity
     * @returns {string}
     */
    hexToRGB(hexColor, opacity = 1) {
      hexColor = hexColor.replace('#')
      const rgb = {
        red: (hexColor >> 16) & 0xFF,
        green: (hexColor >> 8) & 0xFF,
        blue: hexColor & 0xFF,
      }
      return `rgba(${rgb.red},${rgb.blue},${rgb.green},${opacity})`;
    },

    /**
     * Create name icon base on name tech
     * @param tech
     * @returns {string}
     */
    getIconName(tech)
    {
      let iconName = tech.toLowerCase()
          .replace('#', 'sharp')
          .replace('.', '')
          .split('-')[0].split(' ')[0];
      return `mdi-${iconName} mdi-language-${iconName}`;
    }
  }
}
</script>