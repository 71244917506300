<template>
  <v-card rounded="lg" min-height="50vh">
    <v-card-text cols="6" v-if="!loaded">
      <v-skeleton-loader type="card-heading, date-picker-days@2, image@5"/>
    </v-card-text>
    <v-card-text cols="6" v-if="chart.technologies" v-show="cards.includes('technologies')">
      <PieChartContainer :time-report="chart.technologies" title="label.technologies"/>
    </v-card-text>
    <v-card-text cols="6" v-if="chart.projects" v-show="cards.includes('projects')">
      <PieChartContainer :time-report="chart.projects" title="label.projects" color-scheme="rainbow"/>
    </v-card-text>
    <v-card-text v-if="chart.systems" v-show="cards.includes('systems')">
      <PieChartContainer :time-report="chart.systems" title="label.operating_systems"/>
    </v-card-text>
    <v-card-text v-if="chart.editors" v-show="cards.includes('editors')">
      <PieChartContainer :time-report="chart.editors" title="label.ide_browser"/>
    </v-card-text>
    <v-card-text v-if="chart.frameworks" v-show="cards.includes('frameworks')">
      <PieChartContainer :time-report="chart.frameworks" title="label.frameworks"/>
    </v-card-text>
  </v-card>
</template>
<script>
import PieChartContainer from "~/views/layouts/components/PieChartContainer";

export default {
  components: {PieChartContainer},
  props: {
    selectedItem: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loaded: null,
    chart: {
      technologies: null,
      projects: null,
      systems: null,
      editors: null,
      frameworks: null,
    },
    cards: []
  }),
  mounted() {
    this.cards = ['technologies'];
  },
  watch: {
    selectedItem(value) {
      this.cards = [value]
    },
    'chart.technologies'() {
      this.loaded = this.cards.includes('technologies');
    },
    'chart.projects'() {
      this.loaded = this.cards.includes('projects');
    },
    'chart.systems'() {
      this.loaded = this.cards.includes('systems');
    },
    'chart.editors'() {
      this.loaded = this.cards.includes('editors');
    },
    'chart.frameworks'() {
      this.loaded = this.cards.includes('frameworks');
    },
    cards(value) {
      let type = value[0];
      this.loaded = null;
      if (!this.chart[type]) {
        return this.search(type);
      }
      this.loaded = true;
    }
  },
  methods: {
    /**
     * Search time report based on type
     * @param {string} type
     */
    search(type) {
      switch (type) {
        case 'technologies':
          return this.searchTimeReport({search: 'languages'}, data => this.chart.technologies = data);
        case 'projects':
          return this.searchTimeReport({search: 'projects'}, data => this.chart.projects = data);
        case 'systems':
          return this.searchTimeReport({search: 'operating_systems'}, data => this.chart.systems = data);
        case 'editors':
          return this.searchTimeReport({search: 'editors'}, data => this.chart.editors = data);
        case 'frameworks':
          return this.searchTimeReport({search: '%[]%', searchFields: 'entities:not like'}, data => this.chart.frameworks = data);
      }
    },
    /**
     * Search time report
     * @param {object} search
     * @param {function} callback
     */
    searchTimeReport(search, callback) {
      let params = Object.assign({
        search: 'languages',
        searchFields: 'type',
        orderBy: 'date',
        sortedBy: 'asc'
      }, search);
      this.$store.dispatch('time/search', params).then(({data}) => callback(data))
    },
  }
}
</script>